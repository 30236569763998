<template>
  <div>
    <Leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <!-- <ImageList :images="images"></ImageList> -->
    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
      :title="`产品服务背景`"
      :en_title="`Product Service Background`"
    ></Paragraph>
    <Logotitlebox :datalist="datalistx1"></Logotitlebox>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`服务优势`"
      :en_title="`ADVANTAGES AND VALUE OF THE SOLUTION`"
    ></Paragraph>
    <Logotitlebox :datalist="datalistx2"></Logotitlebox>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`服务特色`"
      :en_title="`Service Features`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist" style="margin-left: 15%"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`应用场景`"
      :en_title="`Application Scenarios`"
    ></Paragraph>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl.slice(0, 5)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl.slice(5, 10)"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';

export default {
  name: 'BaaSLeaflet',
  components: {
    // ImageList,
    LeafletPanel,
    Paragraph,
    Lettertitleblock,
    Logotitlebox,
    Titlestrlist,
  },
  data() {
    return {
      title: '数字签章服务',
      body:
        '电子合同、电子签名、电子签章服务，为广大用户提供电子合同签署及证据保全服务。'
        + '电子合同符合《电子签名法》相关规定，利用电子签名技术，确保在线合同及电子签约过程合法有效，'
        + '实现电子合同在线签署、编辑、管理等功能，同时整合提供司法鉴定、网络仲裁及律师服务。',
      // TODO: REPLACE WITH PRIVACY PANEL IMAGE
      image: require('@/assets/image/chain/va/signature/signature.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/signature/9_01.jpg'),
        require('@/assets/image/chain/va/signature/9_02.jpg'),
        require('@/assets/image/chain/va/signature/9_03.jpg'),
        require('@/assets/image/chain/va/signature/9_04.jpg'),
      ],
      datalistx1: [
        {
          imageurl: require('@/assets/image/contentsvg/tikit.svg'),
          title: '签署成本高',
          list: [
            '纸张成本、打印成本、异地签署的',
            '快递成本等，线下纸质签署成本高',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/del.svg'),
          title: '签约效率低',
          list: [
            '异地合同签署都要经历内部审批',
            '来回快递等流程',
            '平均每份合同要耗时3-10天',
            '周期长效率低',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/ding.svg'),
          title: '签约风险高',
          list: [
            '假公章、假签名无法杜绝',
            '公章遗失损坏、盗用私盖、篡改合同频发',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/tie.svg'),
          title: '纸质管理难',
          list: [
            '专场保存，专人管理，查找困难',
            '容易丢失，管理成本高',
            '&nbsp;',
            '&nbsp;',
          ],
        },
      ],
      datalistx2: [
        {
          imageurl: require('@/assets/image/contentsvg/tikit2.svg'),
          title: '签约成本低',
          list: [
            '全流程线上签署',
            '无需快递、无需打印',
            '电子签署成本低',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '线上高效签约',
          list: [
            '随时随地签约',
            '不受场景约束，仅需几分钟',
            '支持多种签约方式：',
            '邮件、微信、短信等',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '安全有保障',
          list: [
            '遵循国家法律法规',
            '身份实名认证、数据加密存储',
            '公证处存证、防篡改抗抵赖',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/db.svg'),
          title: '线上管理简单方便',
          list: [
            '身份在线验证、文档安全存储',
            '模板智能匹配、自动分类归档',
            '查阅简单方便',
            '&nbsp;',
          ],
        },
      ],
      datalist: [
        {
          title: '身份信息认证',
          content:
            '多种身份认证手段在线鉴别核实个人、企业身份确保电子签署者真实的签署身份，不可仿冒与否认。',
        },
        {
          title: 'CA中心认证',
          content:
            '国内第一家权威专业的第三方CA认证机构提供电子认证服务提供合法可靠的电子认证、电子签名/签章、时间戳等服务。',
        },
        {
          title: '电子签章',
          content:
            '为用户提供专业、可信、便捷、高性的电子签章服务解决企业运营中签署可靠电子文件需求保障电子文件真实、完整、不可抵赖性。',
        },
        {
          title: '电子签署',
          content:
            '严格遵循《电子签名法》，符合国家密码管理要求、兼容国际标准，支持USBkey/手写/移动/短信/指纹/人脸/扫码/批量签署满足多场景、各行业的电子签署需求。',
        },
        {
          title: '数据存证',
          content:
            '采用文件加密分散存储确保信息和数据安全存证报告详细记载业务全流程信息，形成可追溯的完整证据链对接第三方公证机关、司法鉴定机构，确保签署结果可信。',
        },
        {
          title: '司法举证',
          content:
            '随时随地多种手段线上线下验证签名有效性真实性可信完整的电子证据链条，为电子签署提供可靠性保障CA验证报告、公证书、司法鉴定报告，已获法院判例认可。',
        },
      ],
      datalistl: [
        {
          title: '电子政府',
          imgsrc: 'A',
          list: ['网上申报、业务审批', '电子合同、电子证照'],
        },
        {
          title: '金融行业',
          imgsrc: 'B',
          list: ['证券开户、银行协议', '期货开户、电子保单'],
        },
        {
          title: '房地产行业',
          imgsrc: 'C',
          list: ['材料采购、交易合同', '物业服务、商业租赁'],
        },
        {
          title: '医疗卫生',
          imgsrc: 'D',
          list: ['电子病历、检验报告', '电子处方、手术确认'],
        },
        {
          title: '人力资源',
          imgsrc: 'E',
          list: ['劳动合同、密保协议', '电子工资单、转岗通知'],
        },
        {
          title: '汽车行业',
          imgsrc: 'F',
          list: ['采购合同、汽车销售', '物流运输、试乘试驾'],
        },
        {
          title: '投招标',
          imgsrc: 'G',
          list: ['招标书、投标书', '中标函、电子合同'],
        },
        {
          title: '供应链',
          imgsrc: 'H',
          list: ['采购合同、销售合同', '订单确认、入库签署'],
        },
        {
          title: '互联网+',
          imgsrc: 'I',
          list: ['租房租车、在线教育', '在线旅游、网红经济'],
        },
        {
          title: '更多行业',
          imgsrc: 'J',
          list: ['企业办公、检验检测', '建设工程、电子商务'],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
